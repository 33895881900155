import React from 'react';
import SplashScreen from '../SplashScreen/SplashScreen';
import './App.scss'

export default function App(props) {
    return (
        <div className='App'>
            <SplashScreen/>
            
        </div>
    );
}